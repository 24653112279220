import type { NextPage } from 'next';
import Login from '@containers/LoginPage';
import WithAuth from '@utils/withAuth';
import Head from 'next/head';

const LoginPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>Account | The Cumin Club</title>
      </Head>
      <Login />
    </>
  );
};

LoginPage.getInitialProps = () => {
  return { isRestricted: true };
};

export default WithAuth(LoginPage);
