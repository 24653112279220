import { Box } from '@mui/material';

import theme from 'src/style-system/theme';
import TCCLogo from '@components/TCCLogo';
import ModalComponent from '@components/ModalComponent';

import { useStyles } from './useStyles';

interface IPageLoader {
  height?: string;
  padding?: string;
}

const PageLoaderModal = ({
  height = '100vh',
  padding = '0px',
}: IPageLoader) => {
  const classes = useStyles();

  return (
    <ModalComponent open={true} handleClose={() => {}} isNoWidthSet>
      <Box
        className={classes.mainContainer}
        sx={{
          height: height,
          padding: padding,
        }}
      >
        <div className={classes.logo}>
          <TCCLogo />
        </div>
      </Box>
    </ModalComponent>
  );
};

export default PageLoaderModal;
