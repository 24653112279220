import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';
import { LOGIN_PAGE_BG_IMG_URl } from '@utils/data';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    minHeight: '100vh',
  },

  leftSectionContainer: {
    flex: 1,
    position: 'relative',
    width: '100%',
    backgroundImage: `url(${LOGIN_PAGE_BG_IMG_URl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  rightSectionContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      minHeight: '100vh',
      overflow: 'scroll',
    },
  },

  headerContainer: {
    width: '100%',
    padding: '20px 25px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  wrapper: {
    padding: '10px 25px 100px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 100px 100px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '60px 25px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '800px',
      padding: '60px 50px',
    },
  },

  loginTitle: {
    marginBottom: '15px',
  },

  formContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '0 15px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '500px',
      padding: '0 30px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '540px',
      padding: '0 40px',
    },
  },

  form: {
    marginTop: '30px',
  },

  formItem: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '15px',
      marginBottom: '30px',
    },
    [theme.breakpoints.up('xl')]: {
      columnGap: '25px',
    },
  },

  input: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '230px',
      marginBottom: '0px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '300px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '330px',
    },
  },

  emailError: {
    zIndex: 10,
    position: 'absolute',
    top: '65px',
  },

  passwordError: {
    zIndex: 10,
    position: 'absolute',
    top: '65px',
  },

  checkboxAndForgetWrapper: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '24px',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  },

  checkbox: {
    [theme.breakpoints.down('lg')]: {
      marginTop: '16px',
    },
  },

  socialMediaBtnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  signInFacebook: {
    borderRadius: '24px',
    margin: '24px 0 4px 0',
    width: '250px',
    padding: '8px 0',
    height: '40px',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },

  signInGoogle: {
    borderRadius: '24px',
    margin: '20px 0 24px 0',
    width: '250px',
    padding: '8px 0',
    height: '40px',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      width: '300px',
      marginBottom: '0px',
    },
  },

  registeredSubscription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '10px',
    marginTop: '35px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  loginBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px 0',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  desktopLoginBtn: {
    width: '350px !important',
    [theme.breakpoints.down('lg')]: {
      width: '400px !important',
    },
    [theme.breakpoints.down('xl')]: {
      width: '425px !important',
    },
  },

  loginBtn: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});
