import { ChangeEvent, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useMutation } from '@apollo/client';
import {
  validateEmail,
  validatePassword,
  validateSimplePassword,
} from '@utils/validator';
import InputError from '@components/InputError';
import { Form } from '@components/UseForm';
import Controls from '@components/Controls';
import CuminButton from '@components/Button';
import { AppRoutes } from '@utils/appRoutes';
import TCCLogo from '@public/assets/svg/TCCLogo.svg';
import { LOGIN_MUTATION } from '@graphql/queries/login';
import useAuth from '@utils/useAuth';
import { getUser } from '@graphql/queries/user';
import PageLoaderModal from '@components/PageLoaderModal';

import { useStyles } from './useStyles';

type ErrorType = {
  email: string | null;
  password: string | null;
};

type initialValuesType = {
  email: string;
  password: string;
};

const initialValues = {
  email: '',
  password: '',
};

enum RegistrationErrorsType {
  INVALID_PASSWORD = 'Invalid password',
}

const Login = () => {
  const [errors, setErrors] = useState<ErrorType>({ email: '', password: '' });
  const [input, setInput] = useState<initialValuesType>(initialValues);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    setAuthState,
    authState,
    detectedCountryCode: countryCode,
  } = useAuth();

  const router = useRouter();

  const classes = useStyles();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (errors.email || errors.password) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
    setInput({
      ...input,
      [name]: value,
    });
  };

  const [login, { data: loginData, loading: loginLoading, error: loginError }] =
    useMutation(LOGIN_MUTATION);

  const resetForm = () => {
    setInput(initialValues);
    setErrors({ email: '', password: '' });
  };

  const validateForm: () => boolean = () => {
    let valid = true;

    const emailValidation = validateEmail(input.email.trim());
    const passwordValidation = validateSimplePassword(input.password.trim());

    if (!emailValidation.valid || !passwordValidation.valid) {
      valid = false;
      setErrors({
        email: emailValidation?.messageKey || null,
        password: passwordValidation?.messageKey || null,
      });
    }
    return valid;
  };

  const getUserData = async (token: any) => {
    setLoading(true);
    try {
      const res = await getUser();
      const userData = res?.data?.getMe;
      if (token && userData) {
        setLoading(false);
        resetForm();
        setAuthState({ token, user: userData });
      }
    } catch (error) {
      setLoading(false);

      // error
      localStorage.removeItem('tcc-jwt-token');
      setAuthState({});
    }
  };

  const handleSignIn = async () => {
    if (validateForm()) {
      // login mutation here
      try {
        const res = await login({
          variables: {
            input: {
              email: input.email.trim(),
              password: input.password.trim(),
            },
          },
        });
        const { token, user } = res.data.login;
        localStorage.setItem('email', input.email);
        localStorage.setItem('tcc-jwt-token', token);
        getUserData(token);
      } catch (error: any) {
        if (error.message) {
          setErrors({
            ...errors,
            password: 'Invalid username or password',
          });
        }
        console.error(error.message);
      }
    }
  };

  useEffect(() => {
    const emailId = localStorage.getItem('email');
    if (emailId) {
      setInput({
        ...input,
        email: emailId,
      });
    }
  }, []);

  //to start login in on hit of Enter from keyboard
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter') {
        handleSignIn();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSignIn]);

  const onClickErrorCloseIcon = (name: string) => {
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const onClickBackArrow = () => {
    router.back();
  };

  return (
    <>
      {loginLoading || loading ? <PageLoaderModal /> : null}
      <Box className={classes.mainContainer}>
        <Box className={classes.leftSectionContainer}></Box>
        <Box
          bgcolor="whitePepper.main"
          className={classes.rightSectionContainer}
        >
          <Box className={classes.headerContainer}>
            <ArrowBackIosIcon
              fontSize="large"
              sx={{
                color: 'cinnamon.main',
                cursor: 'pointer',
              }}
              onClick={onClickBackArrow}
            />

            <Link href={AppRoutes.HOME_ROUTE}>
              <a>
                <TCCLogo />
              </a>
            </Link>
          </Box>
          <Box className={classes.wrapper}>
            <Typography
              variant="h2"
              align="center"
              color="cinnamon.main"
              className={classes.loginTitle}
            >
              Login
            </Typography>
            <Typography
              variant="body2"
              align="center"
              color="cumin.main"
              maxWidth="600px"
            >
              Welcome back! Please enter your details.
            </Typography>

            <Box className={classes.formContainer}>
              <Form onSubmit={handleSignIn} className={classes.form}>
                <Box className={classes.formItem}>
                  <Typography
                    variant="body1"
                    align="left"
                    color="cumin.main"
                    ml={{ xs: 3, md: 0 }}
                    mb={{ xs: 1, md: 0 }}
                  >
                    Email*
                  </Typography>
                  <Box sx={{ position: 'relative' }}>
                    <Controls.InputField
                      name="email"
                      placeholder="Email Address"
                      value={input.email}
                      onChange={handleInputChange}
                      className={classes.input}
                      autoComplete="email"
                    />
                    <InputError
                      error={errors.email}
                      className={classes.emailError}
                      onClickErrorCloseIcon={() =>
                        onClickErrorCloseIcon('email')
                      }
                    />
                  </Box>
                </Box>
                <Box className={classes.formItem}>
                  <Typography
                    variant="body1"
                    align="left"
                    color="cumin.main"
                    ml={{ xs: 3, md: 0 }}
                    mb={{ xs: 1, md: 0 }}
                  >
                    Password*
                  </Typography>
                  <Box sx={{ position: 'relative' }}>
                    <Controls.InputField
                      name="password"
                      placeholder="Password"
                      value={input.password}
                      onChange={handleInputChange}
                      className={classes.input}
                      type="password"
                      autoComplete="current-password"
                    />
                    <InputError
                      error={errors.password}
                      className={classes.passwordError}
                      onClickErrorCloseIcon={() =>
                        onClickErrorCloseIcon('password')
                      }
                    />
                  </Box>
                </Box>
              </Form>
              <Box className={classes.checkboxAndForgetWrapper}>
                <Link href={AppRoutes.FORGOT_PASSWORD}>
                  <Typography
                    variant="body2"
                    align="center"
                    color="cinnamon.main"
                    mt={0.5}
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box className={classes.loginBtnWrapper}>
              <CuminButton
                color="turmeric"
                variant="contained"
                textColor="garamMasala.main"
                borderRadius="24"
                width="425px"
                className={classes.desktopLoginBtn}
                onClick={handleSignIn}
              >
                Log In
              </CuminButton>
            </Box>

            {/* <Box className={classes.registeredSubscription}>
              <Typography variant="body2" align="center" color="cumin.main">
                Ordered, but not activated yet?
              </Typography>
              <Link href={AppRoutes.ACTIVATE_ACCOUNT}>
                <Typography
                  variant="body2"
                  align="center"
                  color="cinnamon.main"
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Activate Now!
                </Typography>
              </Link>
            </Box> */}
            <Box className={classes.loginBtn}>
              <CuminButton
                color="cinnamon"
                variant="contained"
                textColor="whitePepper.main"
                borderRadius="30"
                width="250px"
                height="48"
                onClick={handleSignIn}
              >
                Log In
              </CuminButton>
            </Box>
            <Box className={classes.registeredSubscription}>
              <Typography variant="body2" align="center" color="cumin.main">
                Haven’t ordered yet?
              </Typography>
              <Link href={`/${countryCode}${AppRoutes.ORDER_STEP_ONE}`}>
                <Typography
                  variant="body2"
                  align="center"
                  color="cinnamon.main"
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Start a subscription!
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
